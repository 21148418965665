<template>
    <div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
        <div class="over_bgc">
            <!-- 顶部栏 -->
            <top-banner theme="white" @backHome="backHome"></top-banner>
            <div class="x_between">
                <!-- 左边 -->
                <div class="left" style="flex:1">
                    <!-- 标题 -->
                    <title-enzh enTitle="CHANGE" zhTitle="修改密码" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
                    <div class="idea wrap_x_between">
                        <!-- 图片上传 -->
<!--                        <div>-->
<!--                            <el-upload action="#" list-type="picture-card" :auto-upload="false">-->
<!--                                <img slot="default" src="../assets/image/feedback/add_img_icon.svg" style="width:100%">-->
<!--                                <div slot="file" slot-scope="{file}">-->
<!--                                    <img class="el-upload-list__item-thumbnail" :src="file.url">-->
<!--                                    <span class="el-upload-list__item-actions">-->
<!--                                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">-->
<!--                                            <i class="el-icon-zoom-in"></i>-->
<!--                                        </span>-->
<!--                                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">-->
<!--                                            <i class="el-icon-delete"></i>-->
<!--                                        </span>-->
<!--                                    </span>-->
<!--                                </div>-->
<!--                            </el-upload>-->
<!--                            <el-dialog :visible.sync="dialogVisible">-->
<!--                                <img width="100%" :src="dialogImageUrl">-->
<!--                            </el-dialog>-->
<!--                        </div>-->
                        <!-- 选择反馈类型 -->
                        <div style="width:80%">
                            <div class="title">请填写下边的表格，修改 BIMOM ID 账户密码</div>
                            <div class="title">带*号为必填项</div>
                            <div class="title">注：ACurRebar用户请在软件内修改</div>
<!--                            <el-radio-group v-model="radio" style="width:100%">-->
<!--                                <div class="wrap_x_between">-->
<!--                                    <div v-for="(item,index) in radioList" :key="index" class="radio_item">-->
<!--                                        <el-radio :label="item.id">{{item.label}}</el-radio>-->
<!--                                    </div>-->
<!--                                    <div class="empty"></div>-->
<!--                                    <div class="empty"></div>-->
<!--                                </div>-->
<!--                            </el-radio-group>-->
                        </div>
                    </div>
                    <!-- 输入框 -->
                    <div class="wrap_x_between" style="width:100%">
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">账号</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_account" style="width:100%">
                        </div>
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">密码</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="password" class="input_rect"  v-model="user_pwd" style="width:100%">
                        </div>
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">手机号</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_phone" style="width:100%">
                        </div>
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">确认密码</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="password" class="input_rect" v-model="user_pwd1" style="width:100%">
                        </div>

                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">验证码</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="text" class="input_rect" v-model="code" style="width:100%">
<!--                            <span class="lable" @click="getcode">获取验证码</span>-->
<!--                            <div class="submit" @click="sendmsg">获取验证码</div>-->
                            <div class="submit" @click="getCode" disabled="disabled">{{counttext}}</div>
                        </div>

<!--                        <div class="input_box" style="width:100%">-->
<!--                            <div>-->
<!--                                <span class="lable">企业</span>-->
<!--                                <span class="require_icon">*</span>-->
<!--                            </div>-->
<!--                            <input type="text" class="input_rect" style="width:100%">-->
<!--                        </div>-->
<!--                        <div class="input_box" style="width:100%">-->
<!--                            <div>-->
<!--                                <span class="lable">个人简介</span>-->
<!--                            </div>-->
<!--                            <textarea cols="30" rows="4" style="width:100%;"></textarea>-->
<!--                        </div>-->
                    </div>
                    <div> .</div>
                    <!-- 提交按钮 -->
                    <div class="submit" @click="sendmsg">修改</div>
                </div>
                <!-- 右边 -->
                <div class="right" style="flex:1">
                    <!-- 标题 -->
<!--                    <title-enzh enTitle="CONTACT US" zhTitle="联系我们" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>-->
                    <!-- 图片 -->
                    <img src="@/assets/image/home/sixPage/BIMOM.jpg" class="map_size">
                    <!-- 公司信息 -->
                    <div class="company_info">
                        <div class="address">成都公司：四川省成都市高新区天府五街</div>
                        <div class="wrap_x_between">
                            <div v-for="(item,index) in infoList" :key="index" class="info_item y_center">
                                <img :src="item.icon" class="item_icon">
                                <div v-text="item.name" class="item_name"></div>
                            </div>
                            <div class="empty_company_info"></div>
                        </div>
                    </div>
                    <div class="copyright">版权所有 成都悟空和展昭科技有限公司  丨 技术支持 成都悟空和展昭科技有限公司</div>
                </div>
            </div>
        
        
        </div>
    </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
import {Encrypt} from '../assets/utils/secret.js'
export default {
    components:{
        topBanner,
        titleEnzh
    },
    data(){
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            radioList:[
                {id:1,label:"程序BUG"},
                {id:2,label:"功能建议"},
                {id:3,label:"内容意见"},
                {id:4,label:"网络问题"},
                {id:5,label:"账户问题"},
                {id:6,label:"其他"},
            ],
            radio: 1,
            infoList:[
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"15959358326"},
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"fbr0321"},
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"546570287"},
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"点击咨询在线客服"},
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"546570287@qq.com"},
           ],
            counttext:'获取验证码',
            timer: null,
            user_account:"",
            user_name:"",
            user_phone:"",
            user_pwd: "",
            user_pwd1:"",
            code:"",
        }
    },
    methods: {
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        backHome(){
            this.$router.push("/")
        },
        sendmsg(){
            // alert("待上线！")
            let param = {
                user_account: this.user_account,
                user_pwd: Encrypt(this.user_pwd),
                user_pwd1: Encrypt(this.user_pwd1),
                user_phone: this.user_phone,
                code:this.code,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            console.log(this.currentRow);
            this.axios.post(this.VUE_APP_BASE_API + 'forgetPwd',Encrypt(JSON.stringify(param)), config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.dialogFormVisible = false;
                    alert("修改成功");
                } else {
                    alert("修改失败:  "+ res.data.resultMsg);
                }
            });
        },
        getCode(){
            if(this.disabled===true){
                alert('获取太频繁，请等待');
                return;
            }
            let param = {
                user_phone: this.user_phone,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            console.log(this.currentRow);
            this.axios.post(this.VUE_APP_BASE_API + 'getcode', Encrypt(JSON.stringify(param)), config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.dialogFormVisible = false;
                    alert("获取成功");
                    //this.$router.push("/gongxiao")
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.disabled = true;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                                this.counttext=this.count+"秒后重新获取";
                                this.disabled= true;
                                console.log(this.disabled);
                            } else {
                                this.disabled = false;
                                this.counttext="获取验证码";
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                } else {
                    alert("获取失败:  "+ res.data.resultData);
                }
            });
        }

    }
}
</script>

<style scoped>
.idea >>> .el-upload-list--picture-card .el-upload-list__item{
    width: 120px;
    height: 120px;
}
.idea >>> .el-upload--picture-card{
    width: 120px;
    height: 120px;
    background-color: transparent;
    border: none;
}
.idea >>> .el-radio__label{
    font-size: 18px;
    color: #fff;
}
</style>
<style lang="scss" scoped>
.empty{
    min-width: 110px;
    height: 0px;
}
.empty_company_info{
    width: 250px;
    height: 0;
}
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/feedback/feedback_bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.left{
    padding-right:60px; 
    .idea{
        margin: 20px 0;
        padding: 20px;
        border-radius: 6px;
        border: 2px solid rgba(121, 121, 121, 1);
        .title{
            color: #fff;
            text-align: left;
            font-size: 19px;
        }
        .radio_item{
            min-width: 110px;
            margin-top: 20px;
        }
    }
}
.right{
    padding-left: 60px;
    .map_size{
        width: 100%;
        height: 470px;
        margin: 30px 0;
        border-radius: 10px;
    }
    .company_info{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 18px;
        color: #797979;
        margin-bottom: 20px;
        .address{
            margin-bottom:30px;
            font-weight: bold;
        }
        .info_item{
            font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
            margin-bottom:26px;
            width: 250px;
            .item_icon{
                width:20px;
                margin-right:20px
            }
            .item_name{
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .copyright{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 16px;
        color: #797979;
        font-weight: bold;
    }
}


// 输入框
.input_box{
    // margin-top: 10px;
    .input_rect{
        height: 40px;
        padding: 0 20px;
        border-radius: 6px;
        margin-top: 10px;
        background: rgba($color: #fff, $alpha: .5);
        color: #fff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .lable{
        font-family: '幼圆', sans-serif;
        color: #fff;
        margin-right: 6px;
        font-size: 22px;
    }
    .require_icon{
        font-size: 26px;
        color: #02A7F0;
        vertical-align: middle;
    }
}
textarea{
    background: rgba($color: #fff, $alpha: .5);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    outline:none;
    padding: 20px;
    margin-top: 10px;
}
.submit{
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
    font-size: 18px;
    color: #797979;
    border: 1px solid #797979;
    letter-spacing: 4px;
    border-radius: 20px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    cursor: pointer;
    margin-top: 20px;
    &:hover{
        color: #fff;
        border: 1px solid #fff;
    }
}
.submit1{
    width: 140px;
    text-align: left;
    height: 45px;
    line-height: 45px;
    font-family: '幼圆', sans-serif;
    /*font-family: 'Agency FB Normal', 'Agency FB', sans-serif;*/
    font-size: 18px;
    color: #797979;
    border: 1px solid #797979;
    letter-spacing: 4px;
    border-radius: 10px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    margin-top: 20px;
    &:hover{
        color: #fff;
        border: 1px solid #fff;
    }
}
</style>